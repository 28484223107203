<template>
	<v-card flat class="mt-1" max-width="900" max-height="500" rounded="xl">
		<v-card-text class="px-0 py-0">
			<v-row dense>
				<v-col cols="12" class="pa-0 ma-0">
					<div
						class="borderDash cardSize"
						@dragover.prevent
						@drop.prevent
					>
						<v-card flat height="175">
							<v-card-title class="mb-2">
								<v-file-input
									multiple
									v-model="inputDoc"
									prepend-icon=""
									class="subtitle-2 included"
									ref="fileInputRef"
									style="display: none"
								></v-file-input>
							</v-card-title>
							<v-card-text @drop="dragFile">
								<v-row>
									<v-col cols="12">
										<div
											class="d-flex align-center text-center justify-center primary--text subtitle-1"
										>
											{{ $t("title") }}
											<br />
											{{ $t("label.or") }}
										</div>
									</v-col>
								</v-row>
							</v-card-text>
							<v-card-actions
								class="d-flex justify-center align-center"
							>
								<v-btn
									color="primary"
									outlined
									rounded
									small
									@click.prevent="handleFileImport"
									>{{ $t("label.upload") }}</v-btn
								>
							</v-card-actions>
						</v-card>
					</div>
				</v-col>
				<v-col
					cols="12"
					class="px-0 py-1 mx-0 mb-0 mt-1"
					v-if="documents.length > 0"
				>
					<v-form v-model="validDescription">
						<v-list dense>
							<v-card flat>
								<v-list-item-group>
									<v-list-item
										v-for="(doc, i) in documents"
										:key="i"
										class="pl-2 pr-2"
									>
										<v-list-item-title>
											<div
												class="subtitle-2 text-truncate mt-1"
												style="width: 200px"
											>
												<v-icon
													color="primary"
													class="mt-n1"
													>upload_file</v-icon
												>{{ doc.name }}
											</div>
										</v-list-item-title>
										<v-list-item-action>
											<v-btn
												small
												text
												icon
												class="mr-1"
												color="error"
												@click="deleteFile(i)"
											>
												<v-icon>close</v-icon>
											</v-btn>
										</v-list-item-action>
									</v-list-item>
								</v-list-item-group>
							</v-card>
						</v-list>
					</v-form>
				</v-col>
			</v-row>
		</v-card-text>
	</v-card>
</template>
<script>
import rules from "@/utils/rules.js";

export default {
	components: {},
	mounted() {},
	data: function () {
		return {
			inputDoc: [],
			documents: [],
			fields: [],
			loadFiles: false,
			validDescription: true,
		};
	},
	computed: {
		fileLength: {
			// getter
			get() {
				return this.documents.length > 0 ? true : false;
			},
		},
	},
	watch: {
		fileLength: function (newVal) {
			this.$emit("disabledUpload", newVal);
		},
		validDescription: function (newVal) {
			this.$emit("disabledUpload", newVal);
		},
		inputDoc: function (val) {
			if (val) {
				this.docFile(val);
			}
		},
	},
	created() {
		this.rules = rules;
	},
	methods: {
		handleFileImport() {
			this.isSelecting = true;
			window.addEventListener(
				"focus",
				() => {
					this.isSelecting = false;
				},
				{ once: true }
			);
			this.$refs.fileInputRef.$refs.input.click();
		},
		include() {
			return [document.querySelector(".included")];
		},
		//Upload Functions
		uploadFile() {
			if (this.documents.length < 1) return;
			this.documents.forEach((item) => {
				this.fields.push({ file: item });
			});
			this.$emit("upload", this.fields);
		},
		docFile(inputDocs) {
			inputDocs.forEach((file) => {
				this.documents.push(file);
			});
		},
		dragFile(e) {
			let files = e.dataTransfer.files;
			for (let i = 0; i < files.length; i++) {
				this.documents.push(files[i]);
			}
		},
		deleteFile(index) {
			this.documents.splice(index, 1);
		},
		closeChip(index) {
			this.documents.splice(index, 1);
		},
		clearCache() {
			this.fields = [];
			this.documents = [];
		},
	},
};
</script>

<i18n>
{
  "en": {
	"title": "Drag and drop here"
  },
  "nl": {
	"title": "Sleep hier naartoe"
  },
  "de": {
	"title": "Hierhin ziehen und ablegen"
  }
}
</i18n>

<style scoped>
.cardSize {
	width: 100%;
	height: 100%;
}

.borderDash {
	border-width: 2px;
	box-sizing: border-box;
	border-color: rgb(173, 173, 173);
	border-style: dashed;
}

.rotate-315 {
	-webkit-transform: rotate(315deg);
	-moz-transform: rotate(315deg);
	-ms-transform: rotate(315deg);
	-o-transform: rotate(315deg);
	transform: rotate(315deg);
}

.v-list {
	height: 130px;
	overflow-y: auto;
}
</style>
