<template>
	<v-row justify="center">
		<v-dialog v-model="dialog" persistent max-width="500px">
			<v-card>
				<v-alert v-if="error" tile type="error">{{ error }}</v-alert>

				<v-card-title class="primary white--text">
					<slot name="title"></slot>
				</v-card-title>

				<v-card-text class="pt-5">
					<v-form ref="form">
						<v-row>
							<v-col cols="12">
								<DragAndDropFileUpload
									v-on:upload="saveUpload"
									v-on:disabledUpload="disabledUploadMethod"
									ref="uploadFile"
								>
								</DragAndDropFileUpload>
							</v-col>
						</v-row>
					</v-form>
				</v-card-text>

				<v-card-actions class="pb-5 pr-5">
					<v-spacer></v-spacer>
					<v-btn
						:disabled="isSaving"
						text
						rounded
						@click="closeUploadDialog"
						>{{ $t("label.cancel") }}</v-btn
					>
					<v-btn
						outlined
						rounded
						:loading="isSaving"
						color="primary"
						@click="$refs.uploadFile.uploadFile()"
						>{{ $t("label.save") }}</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-row>
</template>

<script>
import DragAndDropFileUpload from "@/components/base/DragAndDropFileUpload.vue";
import rules from "@/utils/rules.js";

export default {
	name: "drag-and-drop-file-upload-edit-dialog",
	props: ["dialog", "object"],
	components: {
		DragAndDropFileUpload,
	},

	apollo: {},

	data() {
		return {
			error: null,
			isSaving: false,
		};
	},
	computed: {},
	watch: {
		// reset form when dialog open or close
		dialog(val) {
			// if open dialog, initialize selections
			if (val) {
			}
		},
	},
	created() {
		this.rules = rules;
	},
	methods: {
		disabledUploadMethod(val) {
			this.disabledUpload = val;
		},

		closeUploadDialog() {
			this.$refs.uploadFile.clearCache();
			this.$emit("update:dialog", false);
			this.isSaving = false;
		},

		saveUpload(files) {
			this.isSaving = true;

			this.loadingUploadBtn = true;
			let file = [];
			let uploadFields = [];

			files.forEach((e) => {
				file.push(e.file);
				uploadFields.push({
					fileName: e.file.name,
					description:
						e.file.description === undefined ||
						e.file.description === null
							? ""
							: e.file.description,
				});
			});

			this.$emit("update:object", uploadFields);
			this.$emit("update:file", file);
			this.$emit("item-created");
			this.$refs.uploadFile.clearCache();
		},
	},
};
</script>
